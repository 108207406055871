<template>
	<div class="main">
		<div class="header">
			<img class="header-logo-ci" src="@/assets/image/zhfe/zhfCI.png" />
			<img class="header-logo" src="@/assets/image/zhfe/zhfelogo.png" />
			<span class="header-txt">智惠妇儿</span>
			<!-- 关闭 刷新 -->
			<div class="idx-middlesx-box3">
				<div class="idx-middle-1">
					<img src="@/assets/image/zhfe/return.png" @click="getback" />
					<img src="@/assets/image/zhfe/icon/sx.png" alt="" @click="refresh" />
				</div>
			</div>
		</div>
		<div class="time-box">
			<Date></Date>
		</div>
		<div class="title">护苗行动</div>
		<div class="container">
			<div class="awards-filter-wrap">
				<el-form :inline="true" :model="queryParams" :label-position="'top'" class="demo-form-inline">
					<el-form-item label="所属人群：">
						<el-select v-model="queryParams.personType" clearable placeholder="请选择所属人群" @change="handleChangePersonType">
							<el-option v-for="item in personTypeOptions" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="人员类型：">
						<el-select filterable v-model="queryParams.personType2" clearable placeholder="请选择人员类型" @change="() => { }">
							<el-option v-for="item in personnel" :key="item.dataId" :label="item.dataName" :value="item.dataName">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属社区：">
						<el-select v-model="queryParams.area" clearable placeholder="请选择所属社区">
							<el-option v-for="item in area" :key="item.dataId" :label="item.dataName" :value="item.dataName">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属年份：">
						<el-select v-model="queryParams.helpYear" clearable placeholder="所属年份">
							<el-option v-for="item in yearOptions" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="所属季度：">
						<el-select v-model="queryParams.helpQuarter" clearable placeholder="所属季度">
							<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="·" class="operate">
						<el-button type="primary" @click="onSubmit">搜索</el-button>
						<el-button type="primary" @click="onReset">重置</el-button>
						<el-button type="primary" @click="handleEdit">+新增</el-button>
						<el-button type="primary" @click="onPersonnelType">人员类型管理</el-button>
						<el-button type="primary" class="other" @click="onImport">导入</el-button>
					</el-form-item>
				</el-form>
			</div>
			<div class="table-wrap">
				<el-table :data="lingbanList" stripe :style="{
					width: '100%',
					maxHeight: `${windowHeight > 1200
						? 68
						: windowHeight > 1025
							? 56
							: windowHeight > 800
								? 52
								: 42
						}vh`,
					overflowY: ' auto',
				}" header-align="center" align="center" :cell-style="{ background: 'revert' }">
					<el-table-column type="index" label="序号" width="80" align="center">
					</el-table-column>
					<el-table-column prop="personType" label="所属人群" align="center">
					</el-table-column>
					<el-table-column prop="personType2" label="人员类型" align="center">
					</el-table-column>
					<el-table-column prop="area" label="所属社区" align="center">
					</el-table-column>
					<el-table-column prop="personCount" label="人员数量" align="center">
					</el-table-column>
					<el-table-column prop="helpYear" label="所属年份" align="center">
					</el-table-column>
					<el-table-column prop="helpQuarter" label="所属季度" align="center">
					</el-table-column>
					<el-table-column label="操作" align="center" width="120">
						<template slot-scope="scope">
							<el-button type="text" class="delete" @click="handleDelete(scope.row)">删除</el-button>
							｜
							<el-button type="text" class="edit" @click="handleEdit(scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
			</div>
			<div class="pagination-wrap">
				<el-pagination background @size-change="handleSizeChange" layout="total,sizes,prev, pager, next"
					:page-sizes="[10, 20, 50, 100]" @current-change="handleCurrentChange" :total="total"
					:current-page="queryParams.pageNum" :page-size="queryParams.pageSize" :prev-text="'上一页'" :next-text="'下一页'"
					popper-class="pagination-select">
				</el-pagination>
			</div>
		</div>
		<el-dialog :visible.sync="editVisible" title="" class="import-dialog" :append-to-body="true"
			:close-on-click-modal="false">
			<div class="dialog-title">{{ dialogTitle }}</div>
			<el-form ref="form" :inline="true" label-position="right" label-width="120px" :model="form" :rules="rules"
				class="demo-form-inline dialog-form-inline">
				<el-form-item label="所属人群：" prop="personType">
					<el-select v-model="form.personType" clearable placeholder="所属人群" @change="handleChangePersonType2">
						<el-option v-for="item in personTypeOptions" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="人员类型：" prop="personType2">
					<el-select v-model="form.personType2" clearable placeholder="人员类型" @change="handleEditPersonChange">
						<el-option v-for="item in editPerson" :key="item.dataId + item.dataName" :label="item.dataName"
							:value="item.dataName">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属社区：" prop="area">
					<el-select v-model="form.area" clearable placeholder="所属社区">
						<el-option v-for="item in area" :key="item.dataId" :label="item.dataName" :value="item.dataName">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="所属年份：" prop="helpYear">
					<el-date-picker v-model="form.helpYear" format="yyyy" value-format="yyyy" type="year" placeholder="所属年份">
					</el-date-picker>
				</el-form-item>
				<el-form-item label="所属季度：" prop="helpQuarter">
					<el-select v-model="form.helpQuarter" clearable placeholder="所属季度">
						<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="人员数量：" prop="personCount">
					<el-input v-model.number="form.personCount" placeholder=""></el-input>
				</el-form-item>
			</el-form>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="editVisible = false">取 消</el-button>
				<el-button type="primary" @click="handleConfirm">确定{{ dialogTitle }}</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="editVisible = false" alt="" />
		</el-dialog>
		<el-dialog title="" class="import-dialog loading-dialog" :visible.sync="import_dialog" :append-to-body="true"
			:close-on-click-modal="false">
			<div class="dialog-box" v-loading="uploadLoading" element-loading-text="导入中..."
				element-loading-spinner="el-icon-loading" element-loading-background="rgba(0, 0, 0, 0.8)">
				<div class="dialog-title">护苗行动导入</div>
				<div class="dialog-upload">
					<el-upload class="upload-demo" drag action="" :accept="accept" :http-request="uploadHttp" :file-list="fileList"
						:before-upload="handleBeforeUpload" :on-error="handleUploadError" :before-remove="handleRemove" :limit="1">
						<!-- <i class="el-icon-upload"></i> -->
						<div class="el-upload__text">
							<img class="upload-icon" src="@/assets/image/upload.png" alt="" />
							<p>点击或将文件拖拽到这里上传</p>
							<p>支持拓展名：.xls .xlsx</p>
						</div>
						<div class="el-upload__tip" slot="tip" @click="getFileName">
							下载模版
						</div>
					</el-upload>
				</div>
				<div class="dialog-operate-wrap" style="margin-top: 20px">
					<el-button type="primary" @click="import_dialog = false">取消</el-button>
					<el-button type="primary" @click="handleExportConfirm">提交</el-button>
				</div>
				<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="import_dialog = false" alt="" />
			</div>
		</el-dialog>
		<el-dialog title="" class="delete-dialog" :visible.sync="delete_dialog" width="780px" :append-to-body="true"
			:close-on-click-modal="false">
			<div class="dialog-title">系统提示</div>
			<div class="dialog-content">
				是否确认删除“{{ dataDetail && dataDetail.personType }}”数据项？
			</div>
			<span slot="footer" class="dialog-footer">
				<el-button type="primary" @click="delete_dialog = false">取 消</el-button>
				<el-button type="primary" @click="handleDeleteConfirm">确 定</el-button>
			</span>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="delete_dialog = false" alt="" />
		</el-dialog>
		<el-dialog v-if="personnelDialogVisible" title="" class="delete-dialog" :visible.sync="personnelDialogVisible"
			width="80%" :append-to-body="true" :close-on-click-modal="false">
			<div class="dialog-title">人员类型管理</div>
			<el-form :inline="true" label-position="right" label-width="100px" :model="form"
				class="demo-form-inline personnel-type-dialog">
				<el-form-item label="所属人群：">
					<el-select @change="changeDialogDataField1" filterable style="width:200px" v-model="personnelForm.dataField1"
						clearable placeholder="请选择所属人群">
						<el-option v-for="item in personTypeOptions" :key="item" :label="item" :value="item">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="人员类型：">
					<el-select filterable style="width:200px" v-model="personnelForm.dataName" clearable placeholder="请选择人员类型">
						<el-option v-for="item in PersonnelTypeData" :key="item.dataId" :label="item.dataName" :value="item.dataName">
						</el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="状态：">
					<el-select style="width:200px" v-model="personnelForm.status" clearable placeholder="请选择状态" @change="() => { }">
						<el-option label="启用" value="0"></el-option>
						<el-option label="禁用" value="1"></el-option>
					</el-select>
				</el-form-item>
				<el-form-item label="" class="">
					<el-button type="primary" @click="onOperation('query', {})">搜索</el-button>
					<el-button type="primary" @click="onOperation('reset', {})">重置</el-button>
					<el-button type="primary" @click="onOperation('add', {})">新增</el-button>
				</el-form-item>
			</el-form>
			<div class="table-wrap">
				<el-table :data="personnelTableData" stripe :style="{
					width: '100%',
					maxHeight: `${windowHeight > 1200
						? 68
						: windowHeight > 1025
							? 56
							: windowHeight > 800
								? 52
								: 42
						}vh`,
					overflowY: ' auto',
				}" header-align="center" align="center" :cell-style="{ background: 'revert' }" class="personnel_table">
					<el-table-column label="所属人群" prop="dataField1" align="center" width=""></el-table-column>
					<el-table-column label="人员类型" prop="dataName" align="center" width=""></el-table-column>
					<el-table-column label="状态" prop="" align="center" width="">
						<template slot-scope="scope">
							<span>{{ scope.row.status == '0' ? '启用' : '禁用' }}</span>
						</template>
					</el-table-column>
					<el-table-column label="创建时间" prop="createTime" align="center" width="">
						<template slot-scope="scope">
							<span>{{ scope.row.createTime }}</span>
						</template>
					</el-table-column>
					<el-table-column label="操作" align="center" width="">
						<template slot-scope="scope">
							<el-button type="text" class="edit" @click="onOperation('edit', scope.row)">编辑</el-button>
						</template>
					</el-table-column>
				</el-table>
				<div class="pagination-wrap">
					<el-pagination background @size-change="personnelSizeChange" layout="total,sizes,prev, pager, next"
						:page-sizes="[10, 20, 50, 100]" @current-change="personnelCurrentChange" :total="personnelTotal"
						:current-page="personnelForm.pageNum" :page-size="personnelForm.pageSize" :prev-text="'上一页'"
						:next-text="'下一页'" popper-class="pagination-select">
					</el-pagination>
				</div>
			</div>
			<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="onOperation('close', {})" alt="" />
			<!-- 新增/修改弹框 -->
			<el-dialog top="23vh" v-if="addUserTypeDialogVisible" title="" class="delete-dialog"
				:visible.sync="addUserTypeDialogVisible" width="30%" :append-to-body="true" :close-on-click-modal="false">
				<div class="dialog-title">{{ addUserTypeDialogTitle }}</div>
				<el-form ref="addUserTypeForm" :rules="addUserTypeFormRules" label-position="right" label-width="120px"
					:model="addUserTypeForm" class="demo-form-inline add-user-type-form">
					<el-form-item label="类型名称：" prop="dataName">
						<el-input :disabled="addUserTypeDialogTitle.includes('编辑')" maxlength="15"
							v-model.number="addUserTypeForm.dataName" placeholder="请输入类型名称"></el-input>
					</el-form-item>
					<el-form-item label="所属人群：" prop="dataField1">
						<el-select :disabled="addUserTypeDialogTitle.includes('编辑')" v-model="addUserTypeForm.dataField1" clearable
							placeholder="请选择所属人群" @change="handleChangePersonType">
							<el-option v-for="item in personTypeOptions" :key="item" :label="item" :value="item">
							</el-option>
						</el-select>
					</el-form-item>
					<el-form-item label="状态：" prop="status">
						<el-radio-group v-model="addUserTypeForm.status">
							<el-radio label="0">启用</el-radio>
							<el-radio label="1">禁用</el-radio>
						</el-radio-group>
					</el-form-item>
					<el-form-item label="排序：" prop="sort">
						<el-input-number v-model="addUserTypeForm.sort" :min="1" :precision="0"></el-input-number>
					</el-form-item>
				</el-form>
				<img src="../../assets/image/dialogClose.png" class="close-dialog" @click="addUserTypeDialogVisible = false"
					alt="" />
				<span slot="footer" class="dialog-footer">
					<el-button :loading="addUserTypeLoading" type="primary" @click="addUserTypeDialogVisible = false">取
						消</el-button>
					<el-button :loading="addUserTypeLoading" type="primary" @click="onUserTypeDialogBtn">确 定</el-button>
				</span>
			</el-dialog>
		</el-dialog>
	</div>
</template>
<script>
import Date from "@/views/commonComponents/date.vue";
import {
	szdtpersonimportData,
	getszdtpersonadminlist,
	addszdtperson,
	savezdtperson,
	getszdtpersonid,
	deleteszdtperson,
	getRecordYear,
	szdtpersonimportTemplate,
} from "@/api/seedlings";
import { Validate, CatchError } from "@/utils/decorator";
import { listData, addPersonnelType } from "@/api/dtdata";
import { download, getFileName, importData } from "@/api/file";
import { download as downloadFile, resetPage } from "@/utils/index.js";
import { log } from '@antv/g2plot/lib/utils';
export default {
	components: { Date, },
	data() {
		return {
			addUserTypeLoading: false,
			allPersonnelTypeData: [],
			PersonnelTypeData: [],
			addUserTypeDialogVisible: false,
			addUserTypeDialogTitle: '',
			addUserTypeForm: {
				dataName: undefined, // 人员类型名称
				dataField1: undefined, // 所属人群
				status: '0', // 状态
				sort: '0', // 排序
			},
			addUserTypeFormRules: {
				dataName: [
					{ required: true, message: '请输入类型名称', trigger: 'blur' },
				],
				dataField1: [
					{ required: true, message: '请选择所属人群', trigger: 'change' }
				],
			},
			personnelDialogVisible: false,
			personnelForm: {
				pageNum: 1,
				pageSize: 10,
				dataName: undefined,
				dataField1: undefined,
				status: undefined,
				dataModule: "custom_dict",
				dataTyp: "custom_dict_person_type2",
			},
			personnelTotal: 0,
			personnelTableData: [],
			queryParams: {
				pageNum: 1,
				pageSize: 20,
				helpYear: null,
				helpQuarter: null,
				personType: null,
				personType2: null,
				area: null,
			},
			lingbanList: [],
			total: 0,
			yearOptions: [],
			options: [
				{
					value: 1,
					label: "第一季度",
				},
				{
					value: 2,
					label: "第二季度",
				},
				{
					value: 3,
					label: "第三季度",
				},
				{
					value: 4,
					label: "第四季度",
				},
			],
			editVisible: false,
			personTypeOptions: ["重点家庭", "重点妇女", "重点儿童"],
			personType2Options: [],
			area: [],
			dialogTitle: "",
			form: {
				personType: null,
				// personType2: null,
				area: null,
				personCount: null,
				helpYear: null,
				helpQuarter: null,
			},
			rules: {
				personType: [
					{ required: true, message: "请选择重点人群", trigger: "change" },
				],
				personType2: [
					{ required: true, message: "请选择人员类型", trigger: "change" },
				],
				area: [
					{ required: true, message: "请选择所属社区", trigger: "change" },
				],
				personCount: [
					{ required: true, message: "请输入人员数量", trigger: "change" },
					{ type: "number", message: "人员数量必须为数字值" },
				],
				helpYear: [
					{ required: true, message: "请选择所属年份", trigger: "change" },
				],
				helpQuarter: [
					{ required: true, message: "请选择所属季度", trigger: "change" },
				],
			},
			import_dialog: false,
			accept: ".xls,.xlsx",
			fileList: [],
			loading: false,
			uploadLoading: false,
			dataDetail: null,
			delete_dialog: false,
			windowHeight: 0,
		};
	},
	computed: {
		personnel() {
			return this.personType2Options.filter(
				(item) => item.dataField1 === this.queryParams.personType
			);
		},
		editPerson() {
			this.$forceUpdate();
			return this.personType2Options.filter(
				(item) => item.dataField1 === this.form.personType && item.status == '0'
			);
		},
	},
	methods: {
		changeDialogDataField1(v) {
			this.personnelForm.dataName = undefined;
			this.PersonnelTypeData = v ? this.allPersonnelTypeData.filter(item => item.dataField1 == v) : []
		},
		onUserTypeDialogBtn() {
			// addPersonnelType
			this.$refs['addUserTypeForm'].validate((valid) => {
				if (valid) {
					this.addUserTypeLoading = true
					let obj = JSON.parse(JSON.stringify(this.addUserTypeForm))
					addPersonnelType(obj).then((res) => {
						if (res.code == '200') {
							this.$message.success('操作成功')
							listData({
								dataModule: "custom_dict",
								dataTyp: "custom_dict_person_type2",
							}).then((res) => {
								this.personType2Options = res.rows;
							}).catch((err) => { });
							this.getPersonnnelList()
							this.getAllPersonType()
							this.addUserTypeDialogVisible = false;
							this.addUserTypeDialogTitle = ''
							this.addUserTypeForm = {
								dataName: undefined, // 人员类型名称
								dataField1: undefined, // 所属人群
								status: '0', // 状态
								sort: '0', // 排序
							}
							this.mounted()
							this.addUserTypeLoading = false
						} else {
							this.$message.error(res.msg)
							this.addUserTypeLoading = false
						}
					}).catch((err) => {
						this.addUserTypeLoading = false
					});
				} else {
					return false;
				}
			});
		},
		onOperation(type, row) {
			switch (type) {
				case 'close':
					this.personnelForm = {
						pageNum: 1,
						pageSize: 10,
						dataName: undefined,
						dataField1: undefined,
						status: undefined,
						dataModule: "custom_dict",
						dataTyp: "custom_dict_person_type2",
					}
					this.personnelDialogVisible = false
					break;
				case 'query':
				case 'reset':
					if (type == 'reset') {
						this.personnelForm = {
							pageNum: 1,
							pageSize: 10,
							dataName: undefined,
							dataField1: this.personTypeOptions[1],
							status: undefined,
							dataModule: "custom_dict",
							dataTyp: "custom_dict_person_type2",
						}
						this.PersonnelTypeData = this.allPersonnelTypeData.filter(item => item.dataField1 == this.personnelForm.dataField1)
						this.$forceUpdate()
					} else {
						this.personnelForm.pageNum = 1
					}
					this.getPersonnnelList();
					// this.getAllPersonType()
					break;
				case 'add':
				case 'edit':
					this.addUserTypeDialogTitle = type == 'add' ? '新增人员类型' : '编辑人员类型'
					this.addUserTypeDialogVisible = true;
					if (row && row.dataId) {
						this.addUserTypeForm = JSON.parse(JSON.stringify(row))
					} else {
						this.addUserTypeForm = {
							dataName: undefined, // 人员类型名称
							dataField1: undefined, // 所属人群
							status: '0', // 状态
							sort: '0', // 排序
						};
					}
					break;
			}
		},
		async getPersonnnelList() {
			const { rows, total } = await listData(this.personnelForm);
			this.personnelTableData = rows;
			this.personnelTotal = total
		},
		// 人员类型管理弹框
		async onPersonnelType() {
			if (!this.existSystem()) {
				return false;
			}
			await this.getAllPersonType()
			this.personnelForm.dataField1 = this.personTypeOptions[1]
			this.PersonnelTypeData = this.allPersonnelTypeData.filter(item => item.dataField1 == this.personnelForm.dataField1)
			await this.getPersonnnelList()
			this.personnelDialogVisible = true
		},
		async getAllPersonType() {
			const res = await listData({ dataModule: "custom_dict", dataTyp: "custom_dict_person_type2", });
			this.allPersonnelTypeData = res.rows
			this.PersonnelTypeData = res.rows
		},
		handleEditPersonChange(e) {
			this.$forceUpdate();
		},
		handleDeleteConfirm() {
			deleteszdtperson(this.dataDetail.id).then((res) => {
				if (res.code == 200) {
					this.queryParams.pageNum = resetPage(
						this.queryParams.pageNum,
						this.queryParams.pageSize,
						this.total,
						1
					);
					this.getszdtpersonadminlist();
					this.delete_dialog = false;
					// this.$router.go(0); //刷新当前页面
				}
			});
		},
		handleChangePersonType() {
			this.queryParams.personType2 = null;
		},
		handleChangePersonType2() {
			this.$set(this.form, "personType2", "");
		},
		async getFileName() {
			const { msg } = await szdtpersonimportTemplate();
			const resource = await download({ fileName: msg });
			const link = URL.createObjectURL(resource);
			downloadFile(link, "护苗行动导入模版.xlsx");
		},
		uploadHttp({ file }) {
			this.fileList.push(file);
			this.loading.close();
		},
		handleUploadError() {
			this.$message({
				type: "error",
				message: "上传失败",
			});
			this.loading.close();
		},
		handleBeforeUpload(file) {
			this.loading = this.$loading({
				lock: true,
				text: "上传中",
				background: "rgba(0, 0, 0, 0.7)",
			});
			const accept = this.accept.split(",");
			let type = file.name.split(".");
			type = "." + type[type.length - 1];
			const isType = accept.some((item) => {
				return type === item;
			});
			if (!isType) {
				this.$message.error(`上传文件只能是 ${this.accept}格式`);
				this.loading.close();
			}
			const isLt1GB = file.size / 1024 / 1024 / 1024 < 1;
			if (!isLt1GB) {
				this.$message.error("上传文件的大小不能超过 1GB!");
				this.loading.close();
			}
			return isType && isLt1GB;
		},
		handleRemove(file, fileList) {
			this.fileList = fileList;
		},
		onImport() {
			if (!this.existSystem()) {
				return false;
			}
			this.fileList = [];
			this.import_dialog = true;
			this.uploadLoading = false;
		},
		handleExportConfirm() {
			if (this.fileList.length === 0) {
				return this.$message.error("请选择上传文件!");
			}
			this.uploadLoading = true;
			Promise.all(
				this.fileList.map((file) => {
					let formData = new FormData();
					formData.append("file", file);
					return szdtpersonimportData(formData);
				})
			)
				.then(async (res) => {
					const { msg } = res[0];
					if (msg === "操作成功") {
						this.$message({ type: "success", message: "导入成功" });
						this.uploadLoading = false;
						this.$router.go(0); //刷新当前页面
					} else {
						this.$message.info("导入失败,正在下载导入失败数据...");
						this.uploadLoading = false;
						const resource = await download({ fileName: msg });
						const link = URL.createObjectURL(resource);
						downloadFile(link, "护苗行动导入失败数据.xlsx");
					}
				})
				.catch(() => {
					this.uploadLoading = false;
				});
		},
		refresh() {
			this.$router.go(0); //刷新当前页面
		},
		getback() {
			this.$router.go(-1); //刷新当前页面
		},
		handleSizeChange(e) {
			this.queryParams.pageSize = e;
			this.getszdtpersonadminlist();
		},
		handleCurrentChange(e) {
			this.queryParams.pageNum = e;
			this.getszdtpersonadminlist();
		},
		personnelSizeChange(e) {
			this.personnelForm.pageSize = e;
			this.getPersonnnelList()
		},
		personnelCurrentChange(e) {
			this.personnelForm.pageNum = e;
			this.getPersonnnelList();
		},
		onSubmit() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams.pageNum = 1;
			this.getszdtpersonadminlist();
		},

		onReset() {
			if (!this.existSystem()) {
				return false;
			}
			this.queryParams = {
				pageNum: 1,
				pageSize: 20,
				helpYear: null,
				helpQuarter: null,
				personType: this.personTypeOptions[1],
				// personType2: null,
				area: null,
			};
			this.getszdtpersonadminlist();
		},
		async handleDelete(row) {
			if (!this.existSystem()) {
				return false;
			}
			const { id, personType } = row;
			this.dataDetail = row;
			this.delete_dialog = true;
			return;
		},
		handleEdit(row) {
			if (!this.existSystem()) {
				return false;
			}
			const { id } = row;
			this.form = { ...row };
			this.dialogTitle = id ? "修改" : "新增";
			this.editVisible = true;
			this.$nextTick(() => {
				this.$refs["form"].clearValidate();
			});
		},
		@Validate("form")
@CatchError()
async handleConfirm() {
	this.form.id
		? await savezdtperson(this.form)
		: await addszdtperson(this.form);
	this.getszdtpersonadminlist();
	this.editVisible = false;
},
		async getszdtpersonadminlist() {
	const { rows, total } = await getszdtpersonadminlist(this.queryParams);
	this.lingbanList = rows;
	this.total = total;
},
	},
	async mounted() {
	const { data: year } = await getRecordYear();
	this.yearOptions = year;
	const { rows: personType2, total } = await listData({
		dataModule: "custom_dict",
		dataTyp: "custom_dict_person_type2",
	});
	this.personType2Options = personType2;
	const { rows: area } = await listData({
		dataModule: "custom_dict",
		dataTyp: "custom_dict_area",
	});
	this.area = area;
	this.queryParams.personType = this.personTypeOptions[1];
	this.getszdtpersonadminlist();
	this.windowHeight = document.body.clientHeight;
},
};
</script>
<style lang="scss" scoped>
@import "../zhfeHome/index.scss";

div {
	box-sizing: border-box;
}

.title {
	position: fixed;
	top: 7vh;
	z-index: 99;
	height: 5.7vh;
	text-align: center;
	line-height: 5.7vh;
	font-size: 2.4vh;
	font-family: hy;
	margin-top: 1vh;
	text-align: left;
	padding-left: 3.5vw;
	font-family: Microsoft YaHei;
	font-weight: bold;
	color: #e6598e;
}

.main {
	overflow: hidden;
	display: flex;
	flex-direction: column;
	padding-bottom: 2vh;

	.container {
		flex: 1;
		min-width: 980px;
		max-width: 98%;
		width: 98%;
		margin: 0 auto;
		border: 2px solid rgba(255, 212, 228, 1);
		margin-top: 20px;
		padding-bottom: 20px;

		.awards-filter-wrap {
			width: 100%;
			border-bottom: 1px solid rgba(255, 212, 228, 1);
			padding: 20px;
			display: flex;
			justify-content: space-between;
			align-items: flex-end;

			::v-deep .el-form {
				.el-form-item {
					padding-right: 35px;

					.el-form-item__label {
						padding-bottom: 0;
						font-size: 14px;
						font-family: Microsoft YaHei;
						font-weight: bold;
						color: #ce739c;
					}
				}

				.el-input__inner,
				.el-textarea__inner {
					width: initial;
				}
			}
		}

		.table-wrap {
			padding: 20px 20px 0;
		}

		::v-deep .el-table {
			margin: 0;
			background-color: transparent;
			-ms-overflow-style: none;
			overflow: -moz-scrollbars-none;

			&::-webkit-scrollbar {
				width: 0 !important;
			}

			&:before {
				background-color: transparent;
			}

			.el-table__header-wrapper {

				/* height: 35px; */
				tr {
					background-color: transparent;

					.el-table__cell {
						background-color: rgba(180, 75, 122, 0.7);
						padding: 0px;
						height: 35px;

						.cell {
							font-size: 15px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}

			.el-table__row {
				background-color: rgba(180, 75, 122, 0.2);
			}

			.el-table__row--striped {
				background-color: rgba(180, 75, 122, 0.4);

				td.el-table__cell {
					background: transparent;
				}
			}

			th.el-table__cell.is-leaf {
				border-bottom: none;
			}

			td.el-table__cell {
				border-bottom: none;
			}

			.cell {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #fff;
			}
		}

		.delete {
			color: #73ff87;
		}

		.edit {
			color: #379aff;
			margin: 0;
		}
	}
}

::v-deep.operate {
	.el-form-item__label {
		opacity: 0;
	}
}

.el-button--primary {
	/* width: 68px; */
	height: 38px;
	background: rgba(255, 212, 228, 0.4);
	border: 1px solid #ffd4e4;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #eea8c4;
	border-radius: 0px;

	&.other {
		clip-path: polygon(0 0,
				100% 0,
				100% 0,
				100% calc(100% - 12px),
				calc(100% - 12px) 100%,
				0 calc(100% + 12px),
				0 calc(100% + 12px),
				-2px -2px);
		background: linear-gradient(-45deg, #ffd4e4 8px, rgba(255, 212, 228, 0.4) 0) bottom right,
			linear-gradient(45deg,
				rgba(255, 212, 228, 0.4) 8px,
				rgba(255, 212, 228, 0.4) 0) bottom left,
			linear-gradient(135deg,
				rgba(255, 212, 228, 0.4) 8px,
				rgba(255, 212, 228, 0.4) 0) top left,
			linear-gradient(-135deg,
				rgba(255, 212, 228, 0.4) 8px,
				rgba(255, 212, 228, 0.4) 0) top right;
		background-size: 50% 50%;
		background-repeat: no-repeat;
	}
}

::v-deep .el-dialog {
	min-height: 491.08px;

	.el-dialog__body {
		padding: 0 50px 50px;
	}

	.el-upload--picture-card {
		background-color: transparent;
		border: 1px dashed #ffd4e4;

		.el-icon-plus {
			color: #ffd4e4;
		}
	}

	.el-dialog__footer {
		width: 100%;
		display: flex;
		justify-content: center;
		padding-bottom: 50px;
	}

	.close-dialog {
		position: absolute;
		top: -16px;
		right: -16px;
		cursor: pointer;
		z-index: 2001;
	}

	.dialog-title {
		margin: 0 auto;
		min-width: 240px;
		width: max-content;
		padding: 12px 8px;
		display: flex;
		align-items: center;
		justify-content: center;
		height: 62px;
		background: #cd80a2;
		border-radius: 0px 0px 14px 14px;
		background-size: 16px 16px;
		border: 2px solid #fcd2e2;
		margin-bottom: 30px;
		font-size: 22px;
		font-family: Microsoft YaHei;
		font-weight: bold;
		color: #ffffff;
	}

	.el-button--primary {
		width: 180px;
		height: 44px;
		background: #002953;
		border: 1px solid #ffd4e4;
		border-radius: 2px;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffd4e4;
	}

	.el-button+.el-button {
		margin-left: 78px;
	}


}

.pagination-wrap {
	display: flex;
	justify-content: flex-end;
	padding-right: 18px;
	padding-top: 20px;

	.el-pagination__total {
		font-size: 12px;
		font-family: SimSun;
		font-weight: 400;
		color: #cceeff;
		line-height: 18px;
	}
}

::v-deep .demo-form-inline {
	.el-form-item {
		margin-bottom: 0px;
		align-items: center;
		justify-content: center;
		padding-right: 70px;

		.el-form-item__label {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffd4e3;
		}

		.el-date-editor.el-input {
			width: 296px;
		}

		.el-input__inner,
		.el-textarea__inner {
			width: 296px;
			height: 38px;
			background: linear-gradient(270deg,
					rgba(205, 128, 162, 0),
					rgba(216, 149, 178, 0.17),
					rgba(205, 128, 162, 0));
			border: 1px solid #ffd4e4;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffb3cf;
			border-radius: 0;

			&::placeholder {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffb3cf;
			}
		}
	}

	&.dialog-form-inline {
		.el-form-item {
			width: 100%;
			display: flex;
			margin-bottom: 20px;
		}
	}
}

::v-deep .add-user-type-form {
	.el-form-item {
		margin-bottom: 20px;
	}

	.el-radio-group {
		.el-radio__input.is-checked .el-radio__inner {
			border-color: #ffb3cf;
			background: #ffb3cf;
		}

		.el-radio__input.is-checked+.el-radio__label {
			color: #ffb3cf;
		}

		.el-input-number__decrease {
			border-right: 1px solid #ffb3cf;
		}

		.el-input-number__increase {
			border-left: 1px solid #ffb3cf;
		}
	}

	.el-input-number {
		width: 296px;
	}

	.el-input-number__decrease,
	.el-input-number__increase {
		background: transparent;
		color: rgb(255, 212, 227);
		width: 2.05vw;
		font-size: 0.7vw;
	}
}

::v-deep.import-dialog {
	.el-dialog__body {
		padding: 0;
	}

	.el-dialog__header {
		padding: 0;

		.el-dialog__headerbtn {
			top: 30px;
			right: 22px;
			padding: 10px;

			.el-dialog__close {
				color: transparent;
			}
		}
	}

	.dialog-box {
		.close-dialog {
			position: absolute;
			top: -16px;
			right: -18px;
			cursor: pointer;
			z-index: 2001;
		}

		.dialog-content {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: 400;
			color: #ffd4e4;
			margin: 88px 20px 0 0;
			text-align: center;
		}

		.dialog-operate-wrap {
			margin: 76px 20px 0 0;
			display: flex;
			justify-content: center;
			align-items: center;

			.dialog-operate {
				width: 180px;
				height: 44px;
				display: flex;
				justify-content: center;
				align-items: center;
				background: #002953;
				border: 1px solid #ffd4e4;
				border-radius: 2px;
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: 400;
				color: #ffd4e4;
				cursor: pointer;

				&.reset {
					margin-right: 47px;
				}
			}
		}
	}

	&.import-dialog {
		.dialog-box {
			height: 491.08px;
			position: relative;
		}

		.dialog-upload {
			margin: 20px auto;
			display: flex;
			justify-content: center;
			width: 544px;

			.upload-demo {
				width: 100%;

				.el-upload {
					width: 100%;
					height: 146px;
					background: rgba(1, 20, 40, 0.45);
					border-radius: 2px;
					border: 1px solid #ffd4e4;

					.el-upload-dragger {
						width: 100%;
						height: 100%;
						background: transparent;
						border: none;

						.el-upload__text {
							display: flex;
							justify-content: center;
							align-items: center;
							flex-direction: column;
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffd4e4;

							.upload-icon {
								width: 26px;
								display: block;
								margin: 26px 0 0;
							}

							p {
								margin-top: 13px;
							}
						}
					}
				}

				.el-upload__tip {
					font-size: 14px;
					font-family: Microsoft YaHei;
					font-weight: 400;
					color: #ffd4e4;
					cursor: pointer;
					margin-top: 21px;
				}

				.el-upload-list {
					min-height: 40px;
					max-height: 100px;
					overflow-y: auto;

					.el-upload-list__item {
						&:hover {
							background-color: transparent;
						}

						.el-upload-list__item-name {
							font-size: 14px;
							font-family: Microsoft YaHei;
							font-weight: 400;
							color: #ffd4e4;

							.el-icon-document {
								display: none;
							}
						}

						.el-icon-close {
							&::before {
								color: #fff;
								opacity: 1;
							}
						}
					}
				}
			}
		}
	}
}

::v-deep.delete-dialog {
	.el-dialog {
		min-height: 150px;

		.table-wrap {
			padding: 20px 20px 0;
		}

		.el-table {
			margin: 0;
			background-color: transparent;
			-ms-overflow-style: none;
			overflow: -moz-scrollbars-none;

			&::-webkit-scrollbar {
				width: 0 !important;
			}

			&:before {
				background-color: transparent;
			}

			.el-table__header-wrapper {

				/* height: 35px; */
				tr {
					background-color: transparent;

					.el-table__cell {
						background-color: rgba(180, 75, 122, 0.7);
						padding: 0px;
						height: 35px;

						.cell {
							font-size: 15px;
							font-family: Microsoft YaHei;
							font-weight: bold;
							color: #fff;
						}
					}
				}
			}

			.el-table__row {
				background-color: rgba(180, 75, 122, 0.2);
			}

			.el-table__row--striped {
				background-color: rgba(180, 75, 122, 0.4);

				td.el-table__cell {
					background: transparent;
				}
			}

			th.el-table__cell.is-leaf {
				border-bottom: none;
			}

			td.el-table__cell {
				border-bottom: none;
			}

			.cell {
				font-size: 13px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #fff;
			}
		}

		.edit {
			color: #379aff;
			margin: 0;
		}
	}

	.dialog-content {
		height: 120px;
		display: flex;
		align-items: center;
		justify-content: center;
		font-size: 14px;
		font-family: Microsoft YaHei;
		font-weight: 400;
		color: #ffd4e4;
	}
}

::v-deep.personnel-type-dialog {
	.el-form-item {
		margin-bottom: 0px;
		align-items: center;
		justify-content: center;
		padding-right: 0;

		.el-form-item__content {
			.el-button--primary {
				width: auto;
				height: auto;
				margin-left: 20px;
			}
		}

		.el-form-item__label {
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffd4e3;
		}

		.el-date-editor.el-input {
			width: 200px;
		}

		.el-input__inner,
		.el-textarea__inner {
			width: 200px;
			height: 38px;
			background: linear-gradient(270deg,
					rgba(205, 128, 162, 0),
					rgba(216, 149, 178, 0.17),
					rgba(205, 128, 162, 0));
			border: 1px solid #ffd4e4;
			font-size: 14px;
			font-family: Microsoft YaHei;
			font-weight: bold;
			color: #ffb3cf;
			border-radius: 0;

			&::placeholder {
				font-size: 14px;
				font-family: Microsoft YaHei;
				font-weight: bold;
				color: #ffb3cf;
			}
		}
	}

	&.dialog-form-inline {
		.el-form-item {
			width: 100%;
			display: flex;
			margin-bottom: 20px;
		}
	}
}
</style>

<style>
.el-table--enable-row-hover .el-table__body tr:hover>td {
	background-color: rgba(180, 75, 122, 0.7) !important;
}

.pagination-wrap .el-pagination__total {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #cceeff;
	line-height: 18px;
}

.pagination-wrap .el-input__inner {
	background: rgba(9, 21, 45, 0.4);
	border: 1px solid #b44b7a;
	color: #ffffff;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
}

.pagination-wrap .btn-prev,
.pagination-wrap .btn-next {
	width: 80px;
	background-color: rgba(180, 75, 122, 0.2) !important;
	border: 1px solid #b44b7a;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
	line-height: 14px;
}

.pagination-wrap .btn-prev:disabled,
.pagination-wrap .btn-next:disabled {
	width: 80px;
	background-color: rgba(180, 75, 122, 0.4);
	border: 1px solid #b44b7a;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
	line-height: 14px;
}

.pagination-wrap .number {
	background-color: rgba(180, 75, 122, 0.4) !important;
	border: 1px solid #b44b7a !important;
	box-shadow: 0px 1px 2px 0px rgba(10, 2, 5, 0.4);
	border-radius: 0px;
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #fcd2e2 !important;
}

.pagination-wrap .active {
	font-size: 12px;
	font-family: SimSun;
	font-weight: 400;
	color: #ffffff !important;
	background: url("../../assets/image/zhfe/pagination_bg.png");
	background-position: center;
	background-repeat: no-repeat;
	background-size: 100% 100%;
	border: none !important;
}
</style>
